import cn from 'classnames'
import { NextPage } from 'next'
import React, { useState } from 'react'
import { Collections } from 'src/components/Collections/Collections'
import { ExpertsCarousel } from 'src/components/ExpertsCarousel/ExpertsCarousel'
import { Footer } from 'src/components/Footer/Footer'
import { PremiumModal } from 'src/components/global/PremiumModal'
import { ThemeOnly } from 'src/components/global/ThemeOnly'
import { Header } from 'src/components/Header/Header'
import { Hero } from 'src/components/Hero/Hero'
import { PopularTopics } from 'src/components/Home/PopularTopics/PopularTopics'
import { HomeTopics } from 'src/components/HomeTopics/HomeTopics'
import { IltaFooter } from 'src/components/IltaFooter/IltaFooter'
import { IltaSponsorshipLevels } from 'src/components/IltaSponsorshipLevels/IltaSponsorshipLevels'
import { LastFromVendorCheatsheet } from 'src/components/LastFromVendorCheatsheet/LastFromVendorCheatsheet'
import { PremiumContentCategories } from 'src/components/PremiumContentCategories/PremiumContentCategories'
import { Testimonials } from 'src/components/Testimonials/Testimonials'
import { TrendingTopics } from 'src/components/TrendingTopics/TrendingTopics'
import { THEME, Theme } from 'src/config/config'
import {
  IIltaSponsorshipItemFragment,
  IPopularTopic,
  IPremiumAudienceItemFragment,
  ISubTopicItemFragment,
  ITestimonialItemFragment,
  ITopicItemFragment,
} from 'src/graphql/generated/hooks'
import { useAuthenticated } from 'src/hooks/useAuthenticated'
import { Meta } from 'src/layout/Meta'
import { Main } from 'src/templates/Main'

// import { BuyerSection } from './BuyerSection'
import { HomePremiumSection } from './HomePremiumSection'
import { PremiumCategories } from './PremiumCategories'
import { VendorSection } from './VendorSection'

interface BasicHomePageProps {
  theme: Theme
}
interface MainHomePageProps extends BasicHomePageProps {
  theme: 'legaltech'
  audiences: IPremiumAudienceItemFragment[]
  premiumCategories: (ITopicItemFragment | ISubTopicItemFragment)[]
  topics: ITopicItemFragment[]
  testimonials: ITestimonialItemFragment[]
  popularTopics: IPopularTopic[]
}

interface IltaHomePageProps extends BasicHomePageProps {
  theme: 'ilta'
  topics: ITopicItemFragment[]
  iltaSponsorships: IIltaSponsorshipItemFragment[]
}

export type HomePageProps = MainHomePageProps | IltaHomePageProps

const isMainHomePageProps = (props: HomePageProps): props is MainHomePageProps => props.theme === 'legaltech'
const isIltaHomePageProps = (props: HomePageProps): props is IltaHomePageProps => props.theme === 'ilta'

const Home: NextPage<HomePageProps> = (props) => {
  const { topics } = props
  const [isPremiumPreview, setIsPremiumPreview] = useState(false)
  const [isPremiumModalVisible, setIsPremiumModalVisible] = useState(false)
  const { isPremium, isVendor } = useAuthenticated()
  const title = 'Legaltech Hub'
  const description =
    'Legaltech Hub is the global directory of legal technology solutions, tools and services where buyers of legal technology can research the legal tech they need to manage a 21st century law firm or in-house department, vendors can showcase their solutions, and investors can understand the marketplace.'

  const meta = <Meta title={title} description={description} url="/" />
  const isPremiumMode = isPremium || isPremiumPreview

  return (
    <Main meta={meta}>
      <ThemeOnly theme="legaltech">
        <Header />
      </ThemeOnly>
      <PremiumModal origin="/home/" isOpen={isPremiumModalVisible} onClose={() => setIsPremiumModalVisible(false)} />
      <Hero isPremiumPreview={isPremiumPreview} onPreviewPremium={() => setIsPremiumPreview(true)} />
      <h1 className="hidden">Legaltech Hub</h1>
      {!isPremiumMode && (
        <div>
          <div className="w-full px-3 large-lg:px-0 min-h-[28.75rem] bg-clouds pb-6">
            <div className="!max-w-[1100px] flex flex-col justify-center m-auto">
              <span
                className={cn('text-center mt-10 mb-6 font-bold text-[1.5rem] text-[#222222] font-secondary', {
                  '!text-[#3D4246] !font-primary': THEME === 'ilta',
                })}
              >
                Browse by Topic
              </span>
              <HomeTopics className={cn({ '!text-textdark': THEME === 'ilta' })} topics={topics} />
            </div>
          </div>
          {isMainHomePageProps(props) && (
            <ThemeOnly theme="legaltech">
              <HomePremiumSection />
              {/* <BuyerSection /> */}
              <VendorSection />
              <PopularTopics topics={props.popularTopics} />
              <Testimonials testimonials={props.testimonials} />
            </ThemeOnly>
          )}
        </div>
      )}
      <ThemeOnly theme="legaltech">
        {isPremiumMode && isMainHomePageProps(props) && (
          <div>
            <PremiumContentCategories isVendor={isVendor} />
            <PremiumCategories premiumCategories={props.premiumCategories} topics={topics} />
          </div>
        )}
        {isVendor && <LastFromVendorCheatsheet />}
        {isPremiumMode && isMainHomePageProps(props) && (
          <div>
            {/* {SHOW_COLLECTION && <Collections />} */}
            <Collections />
            {props.audiences.map((audience) => (
              <TrendingTopics
                key={audience.id!}
                audience={audience}
                onClickWhenDisabled={() => setIsPremiumModalVisible(true)}
              />
            ))}
            <ExpertsCarousel />
          </div>
        )}
      </ThemeOnly>

      <ThemeOnly theme="ilta">
        <div className="w-full px-3 large-lg:px-0 min-h-[28.75rem] bg-white pb-6">
          <div className="!max-w-[1100px] flex flex-col justify-center m-auto">
            <span className="text-center mt-10 mb-6 font-bold text-[2rem] text-textdark font-primary">
              Browse by sponsor levels
            </span>
            <span className="text-center mb-6 font-secondary text-[16px]">
              Search for ILTA business partners and consultants who offer products and services in the legal community.
            </span>
            <div className="mt-[20px] mb-[88px]">
              {isIltaHomePageProps(props) && <IltaSponsorshipLevels litaSponsorships={props.iltaSponsorships} />}
            </div>
          </div>
        </div>
      </ThemeOnly>

      <ThemeOnly theme="legaltech">
        <Footer />
      </ThemeOnly>

      <ThemeOnly theme="ilta">
        <IltaFooter />
      </ThemeOnly>
    </Main>
  )
}

export default Home
